








import { Component, Vue, Prop } from 'vue-property-decorator';
import { DashboardPageType, IndividualDataTableDashboardPage, Dashboard } from '@/models/hcad/shared/dashboard';
import { pageEditorComponents } from '@/utils/typed-configs';
import { validateRefs } from '@/utils/validate-refs';
import FieldList from '../FieldList.vue';

@Component({components: {FieldList}})
export default class IndividualDataTableConfigEdit extends Vue
{
    @Prop({type: Object, required: true})
    page!: IndividualDataTableDashboardPage;

    @Prop({type: Object, required: false})
    dashboard!: Dashboard;

    get pageSelectOptions()
    {
        const list: { text: string; value: number | null | undefined }[] = [
            { text: 'None', value: null },
        ];

        for(let i = 0; i < this.dashboard.pages.length; ++i)
        {
            const page = this.dashboard.pages[i];
            if (page.type === DashboardPageType.Benchmark)
            {
                list.push({
                    text: page.name,
                    value: i
                });
            }
        }

        return list;
    }

    validate()
    {
        return validateRefs(this.$refs);
    }
}

pageEditorComponents
    .registerComponent(DashboardPageType.IndividualDataTable, IndividualDataTableConfigEdit)
    .registerDataFactory(DashboardPageType.IndividualDataTable, ()=>new IndividualDataTableDashboardPage)
;
