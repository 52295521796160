/* eslint-disable @typescript-eslint/no-explicit-any */

export const questions = new Map<any, string>([
    [0, `
    <div style="font-weight: bold;">Potential Question Prompt:</div>
    <div>Can you share an example of a time when you had to unexpectedly help someone due to the absence of someone else?</div>
    <br/>
    <div style="font-weight: bold;">Related Follow-Up Questions to Gather Context:</div>
    <ul style="margin: 0;">
        <li>Were any others involved?</li>
        <li>How did the person you helped react to your assistance?</li>
        <li>Would you do anything differently today?</li>
    </ul>
    <br/>
    <div style="font-weight: bold;">Internal Interviewer Notes:</div>
    <div>A decision toward fulfilling the person's potential needs hints at Execution and Savvy. An answer that suggests connecting the person to others who might better help hints at Collaboration.</div>
    `],
    [1, `
    <div style="font-weight: bold;">Potential Question Prompt:</div>
    <div>Share a story about a time at work when you were asked your opinion in order to help resolve a difference of opinion between two other people.</div> 
    <br/>
    <div style="font-weight: bold;">Related Follow-Up Questions to Gather Context:</div>
    <ul style="margin: 0;">
        <li>What contributed to your opinion?</li>
        <li>Were the people at the same job level as each other?</li>
        <li>Were the people at the same job level as you?</li>
        <li>Did the people execute based on your opinion?</li>
    </ul>
    <br/>
    <div style="font-weight: bold;">Internal Interviewer Notes:</div>
    <div>A decision toward blending the opinions of the two people into a compromise hints at Collaboration. A decision toward supporting a person at a more senior job level hints at Execution and Savvy.</div>
    `],
    [2, `
    <div style="font-weight: bold;">Potential Question Prompt:</div>
    <div>Describe a time when you were a part of a group that fell significantly short of meeting an important goal.</div> 
    <br/>
    <div style="font-weight: bold;">Related Follow-Up Questions to Gather Context:</div>
    <ul style="margin: 0;">
        <li>How much impact did you individually have on the goal outcome?</li>
        <li>How did you react after learning about missing the goal?</li>
        <li>Did you interact with any other group members?</li>
        <li>Would you do anything differently in the future?</li>
    </ul>
    <br/>
    <div style="font-weight: bold;">Internal Interviewer Notes:</div>
    <div>An answer that indicates the individual focused on their own work or prepared themself for the worst outcome may hint at Execution. An answer demonstrating that the individual made recommendations to others hints at Innovation and Collaboration.</div>
    `],
    [3, `
    <div style="font-weight: bold;">Potential Question Prompt:</div>
    <div>Walk me through a time when you intentionally tried to learn something new instead of continuing work on something familiar.</div> 
    <br/>
    <div style="font-weight: bold;">Related Follow-Up Questions to Gather Context:</div>
    <ul style="margin: 0;">
        <li>How did it feel?</li>
        <li>Did you encounter any obstacles? What were they?</li>
        <li>What was the outcome of your learning attempt?</li>
        <li>Given another opportunity, would you do it again?</li>
    </ul>
    <br/>
    <div style="font-weight: bold;">Internal Interviewer Notes:</div>
    <div>An answer that indicates the individual was willing to try something new without much difficulty may suggest a type of corporate Innovation that favors novel challenges and demonstrates a growth mindset.</div>
    `],
    [4, `
    <div style="font-weight: bold;">Potential Question Prompt:</div>
    <div>Please describe a time in which you had to work on a project that didn't have well-defined instructions.</div> 
    <br/>
    <div style="font-weight: bold;">Related Follow-Up Questions to Gather Context:</div>
    <ul style="margin: 0;">
        <li>In your opinion, what information was missing?</li>
        <li>Did you involve any other people?</li>
        <li>What resources did you have to aid your work?</li>
        <li>How did you feel about the outcome of the project?</li>
    </ul>
    <br/>
    <div style="font-weight: bold;">Internal Interviewer Notes:</div>
    <div>An answer that indicates the individual was successfully able to seek out additional resources or expertise in order to improve project outcomes hints at Savvy.</div>
    `],
    [5, `
    <div style="font-weight: bold;">Potential Question Prompt:</div>
    <div>Share a story about a situation when you specifically chose to engage others to work with you on a project that needed to be completed quickly--instead of taking on the project all by yourself.</div> 
    <br/>
    <div style="font-weight: bold;">Related Follow-Up Questions to Gather Context:</div>
    <ul style="margin: 0;">
        <li>Why did you involve others?</li>
        <li>What were the outcomes related to involving others?</li>
        <li>Would you approach the project any differently if you had to do it again?</li>
    </ul>
    <br/>
    <div style="font-weight: bold;">Internal Interviewer Notes:</div>
    <div>An answer that indicates the individual has chosen to engage others on a project with a quick deadline instead of taking it on by themself suggests one who may value the behavior of Collaboration to engage others in change to produce better project outcomes.</div>
    `],
    [6, `
    <div style="font-weight: bold;">Potential Question Prompt:</div>
    <div>Can you describe an example of a time when you proactively shared an idea with someone in leadership?</div> 
    <br/>
    <div style="font-weight: bold;">Related Follow-Up Questions to Gather Context:</div>
    <ul style="margin: 0;">
        <li>What prompted you to share the idea?</li>
        <li>What was your approach for communicating the idea?</li>
        <li>What are your observations about your experience sharing the idea with leadership?</li>
        <li>Would you do anything differently next time?</li>
    </ul>
    <br/>
    <div style="font-weight: bold;">Internal Interviewer Notes:</div>
    <div>An answer that indicates the individual has successfully experienced sharing an unsolicited idea to leadership suggests an orientation toward Execution that relates to assuredness, decisiveness, and independence.</div>
    `],
    [7, `
    <div style="font-weight: bold;">Potential Question Prompt:</div>
    <div>Can you tell me about a time in which you chose between working on a project that was similar to what you've encountered in the past, and a project that was new and would require some research and experimentation?</div> 
    <br/>
    <div style="font-weight: bold;">Related Follow-Up Questions to Gather Context:</div>
    <ul style="margin: 0;">
        <li>What did you consider in making your project choice?</li>
        <li>Would you make the same project choice again?</li>
        <li>What factors might cause you to change your project choice in the future?</li>
    </ul>
    <br/>
    <div style="font-weight: bold;">Internal Interviewer Notes:</div>
    <div>An answer that demonstrates that the individual has previously chosen a new project over a familiar project hints at a willingness to Innovate and their potential attraction to novel experiences and an internal self-belief.</div>
    `],
    [8, `
    <div style="font-weight: bold;">Potential Question Prompt:</div>
    <div>Please describe a time when you encountered two different approaches to solving a problem, and then offered a solution that blended the approaches.</div> 
    <br/>
    <div style="font-weight: bold;">Related Follow-Up Questions to Gather Context:</div>
    <ul style="margin: 0;">
        <li>What resources did you use to suggest a solution?</li>
        <li>Who else was involved?</li>
        <li>What was the outcome of the blended solution?</li>
    </ul>
    <br/>
    <div style="font-weight: bold;">Internal Interviewer Notes:</div>
    <div>An answer that demonstrates that the individual has previously evaluated different approaches and successfully offered a blended solution shows a kind of Savvy around maximizing resources and supporting multiple perspectives since there was no clear "right answer" approach.</div>
    `],
    [9, `
    <div style="font-weight: bold;">Potential Question Prompt:</div>
    <div>Can you share an example of a time when you recognized that a close, trusted colleague of yours who was normally outgoing had become quiet and withdrawn?</div> 
    <br/>
    <div style="font-weight: bold;">Related Follow-Up Questions to Gather Context:</div>
    <ul style="margin: 0;">
        <li>Did you take any action upon this observation? What was the action?</li>
        <li>Did you interact with any others in the process?</li>
        <li>Would you change your reaction to the situation if it happened again?</li>
    </ul>
    <br/>
    <div style="font-weight: bold;">Internal Interviewer Notes:</div>
    <div>The way in which the individual answers can hint at their tendency toward Collaboration. Someone who inquires privately and directly with the colleague may be more Collaborative and likely to recognize prosocial norms, demonstrate empathy, and be perceptive of others' behavior.</div>
    `],
    [10, `
    <div style="font-weight: bold;">Potential Question Prompt:</div>
    <div>Tell me about a time when you were a part of a group that was informed of a new technology initiative that would change how you do some of your work.</div> 
    <br/>
    <div style="font-weight: bold;">Related Follow-Up Questions to Gather Context:</div>
    <ul style="margin: 0;">
        <li>What were your initial reactions to the change announcement?</li>
        <li>Did you take any steps to prepare for the change? What were they?</li>
        <li>If you had been in charge of rolling out the change initiative, would you have done anything differently?</li>
    </ul>
    <br/>
    <div style="font-weight: bold;">Internal Interviewer Notes:</div>
    <div>An individual who indicates taking a proactive approach to educate themself about the requirements of the change may be expressing Execution, corporate Savvy or both. Execution can be characterized by being an early adopter. Corporate Savvy may be expressed by recognizing what would be desirable for the organization or prioritizing firm needs over individual needs.</div>
    `],
    [11, `
    <div style="font-weight: bold;">Potential Question Prompt:</div>
    <div>Share a story about when you have had multiple tasks to complete while being exposed to some distraction.</div> 
    <br/>
    <div style="font-weight: bold;">Related Follow-Up Questions to Gather Context:</div>
    <ul style="margin: 0;">
        <li>What was your approach for completing the tasks?</li>
        <li>Have you encountered this type of scenario frequently? If so, has your process for managing your approach been consistent?</li>
        <li>Describe how you have felt in this type of situation in the past.</li>
    </ul>
    <br/>
    <div style="font-weight: bold;">Internal Interviewer Notes:</div>
    <div>For individuals who indicate past experience with creating focused task time away from distraction, we have found a correlation to Innovation through an understanding of the need to create a process to improve creativity and focus.</div>
    `],
    [12, `
    <div style="font-weight: bold;">Potential Question Prompt:</div>
    <div>Walk me through a past experience in which you were a part of team that performed well enough to be given increased work as a result. </div> 
    <br/>
    <div style="font-weight: bold;">Related Follow-Up Questions to Gather Context:</div>
    <ul style="margin: 0;">
        <li>How did your team respond to the increased work?</li>
        <li>What was your individual role in the team's response?</li>
        <li>How did you feel about the team's increased workload?</li>
        <li>If you were in charge, would you have done anything differently?</li>
    </ul>
    <br/>
    <div style="font-weight: bold;">Internal Interviewer Notes:</div>
    <div>For individuals who indicated a tendency to try and rework the team's processes in anticipation of increased workload, they may be demonstrating Collaboration in terms of awareness and empathy for trying to manage team and organizational needs.</div>
    `],
]);

export const capabilityNameToQuestionsMap = new Map<string, any[]>([
    ['Execution', [0, 1, 2, 6, 10]],
    ['Innovation', [2, 3, 7, 11]],
    ['Collaboration', [0, 1, 2, 5, 9, 12]],
    ['Savvy', [0, 1, 4, 8, 10]],
]);
