var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"border-radius":"8px","border":"2px solid grey"},attrs:{"flat":""}},[_c('v-tabs',{staticStyle:{"border-radius":"8px","height":"32px"},attrs:{"grow":"","hide-slider":""},model:{value:(_vm.selectedCapabilityIndex),callback:function ($$v) {_vm.selectedCapabilityIndex=$$v},expression:"selectedCapabilityIndex"}},_vm._l((_vm.capabilities),function(c,cIndex){return _c('v-tab',{key:c.name,staticStyle:{"border":"0.5px solid grey","border-bottom":"none","height":"32px"},style:({
                'background-color': c.color,
                'border-radius': (
                    (cIndex === 0)
                        ? '7px 0 0 0'
                        : ((cIndex === _vm.capabilities.length - 1)
                            ? '0 7px 0 0'
                            : undefined
                        )
                ),
            })})}),1),_c('v-tabs-items',{model:{value:(_vm.selectedCapabilityIndex),callback:function ($$v) {_vm.selectedCapabilityIndex=$$v},expression:"selectedCapabilityIndex"}},_vm._l((_vm.capabilities),function(c){return _c('v-tab-item',{key:c.name},[_c('div',{staticStyle:{"padding":"8px","color":"white","font-size":"21px"},style:({
                    'background-color': c.color,
                })},[_vm._v(" "+_vm._s(c.name)+" ")]),_c('div',{staticStyle:{"padding":"6px","color":"white","font-size":"15px","background-color":"grey"}},[_vm._v(" Interview Questions ")]),_c('div',{staticClass:"d-flex flex-row"},[_c('v-tabs',{staticStyle:{"min-width":"32px","width":"32px","max-height":"210px"},attrs:{"vertical":"","grow":"","hide-slider":""},model:{value:(_vm.selectedQuestionIndex),callback:function ($$v) {_vm.selectedQuestionIndex=$$v},expression:"selectedQuestionIndex"}},_vm._l((_vm.interviewQuestions),function(q,iqIndex){return _c('v-tab',{key:("iq-" + iqIndex),staticStyle:{"color":"white","min-width":"32px","padding":"0"},style:({
                            'background-color': ((iqIndex === _vm.selectedQuestionIndex) ? c.color : 'grey'),
                            'max-height': ("calc(210px / " + (_vm.interviewQuestions.length) + ")"),
                        })},[_vm._v(_vm._s(iqIndex + 1))])}),1),_c('v-tabs-items',{model:{value:(_vm.selectedQuestionIndex),callback:function ($$v) {_vm.selectedQuestionIndex=$$v},expression:"selectedQuestionIndex"}},_vm._l((_vm.interviewQuestions),function(q,iqIndex){return _c('v-tab-item',{key:("iq-" + iqIndex)},[_c('div',{staticStyle:{"color":"grey","font-size":"15px","background-color":"white","padding":"8px","overflow":"auto","max-height":"210px"}},[_c('div',{domProps:{"innerHTML":_vm._s(q)}})])])}),1)],1)])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }