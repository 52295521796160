



































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import copy from 'copy-to-clipboard';
import { LineChart } from 'vue-chart-3';

import {
    DashboardPageType,
    IndividualDataTableDashboardPage, 
    IndividualDataTableUserData, 
    IndividualDataTableQueryResult,
    FieldValueType,
    DashboardPageFieldType,
    DashboardPageFilterType,
    MetricsToggleQueryData,
    BaseFilterQueryData,
    RoleBaselineQueryData,
    BaseDashboardPageField,
    DashboardPageRoleBaselineFilter
} from '@/models/hcad/shared/dashboard';

import { pageViewerComponents, fieldViewerComponents } from '@/utils/typed-configs';
import dataSourceModule from '@/store/modules/DataSourceModule';
import dashModule from '@/store/modules/DashboardModule';
import { UserInfoAndMetrics, FilterValueType, RoleBaselineReferenceType } from '@/models/hcad/shared/queries';
import RowRenderer from './IndividualDataTableRowRenderer.vue';
import Gem from '../../components/Gem.vue';
import ModalContent from './IndividualDataTableUserModalContent.vue';
import roleBaselineModule from '@/store/modules/RoleBaselineModule';
// import MatchPercentRenderer from './individual-data-table-renderers/MatchPercentRenderer.vue';
import RoleBaseline from '@/models/hcad/shared/role-baseline';
import { downloadIndividualDataTableCSV } from '@/utils/csv-util';

@Component({
    components: {
        RowRenderer,
        Gem,
        LineChart,
        // MatchPercentRenderer,
        ModalContent
    }
})
export default class IndividualDataTable extends Vue
{
    @Prop({type: Object, required: true})
    page!: IndividualDataTableDashboardPage;

    @Prop({type: Number, required: true})
    index!: number;

    @Prop({type: Array, required: true})
    filterState!: BaseFilterQueryData[];

    @Prop({type: Array, required: true})
    desiredMetrics!: string[];

    DashboardPageFieldType = DashboardPageFieldType; // for use in template

    selectedUser: UserInfoAndMetrics<IndividualDataTableUserData> | null = null;
    selectedUserDataFields: [DashboardPageFieldType, FieldValueType][] | null = null;

    isChartReady = false;

    updateSelectedUser(user: UserInfoAndMetrics<IndividualDataTableUserData> | null)
    {
        this.$set(this, 'selectedUser', user);
    }

    get selectedUserReq()
    {
        return this.selectedUser as UserInfoAndMetrics<IndividualDataTableUserData>;
    }

    get modalVisible()
    {
        return this.selectedUser !== null;
    }

    get capabilities()
    {
        return this.dashboard.capabilities.flatMap(v=>v.capabilities);
    }

    get selectedUserCapabilities()
    {
        const user = this.selectedUserReq;
        const topLevelCapabilities: number[] = [];

        let curIdx = 0;
        for (const cat of this.dashboard.capabilities)
        {
            for (const cap of cat.capabilities)
            {
                topLevelCapabilities.push(curIdx);
                curIdx += cap.subCapabilities.length + 1;
            }
        }

        return topLevelCapabilities.map(idx=>user.metrics.values[idx]);
    }

    get allSelectedUserMetrics()
    {
        return this.selectedUserReq.metrics.values;
    }

    get selectedUserChartData()
    {
        return {
            datasets: [{
                data: this.selectedUserCapabilities,
                backgroundColor: '#86C9CF',
                label: 'Capabilities',
                indexAxis: 'y',
                align: 'right'
            }],
            labels: this.capabilities.map(a=>a.name)
        };
    }

    get areaChartOptions()
    {
        return {
            fill: true,
            maintainAspectRatio: false,
            scales: {
                // TODO: Figure out why tf this breaks the chart
                // x: {
                //     min: this.dashboard.capabilityRange[0],
                //     max: this.dashboard.capabilityRange[1]
                // },
                // y: {}
            }
        };
    }

    get baselineFilterConfig()
    {
        return this.page.filterConfig.filters.find(
            (f) => (f.type === DashboardPageFilterType.RoleBaseline)
        ) as DashboardPageRoleBaselineFilter;
    }

    get baselineFilterState()
    {
        return this.filterState.find(
            (fs) => (fs.type === DashboardPageFilterType.RoleBaseline)
        ) as RoleBaselineQueryData;
    }

    get selectedUserFit()
    {
        if (this.selectedUserDataFields)
        {
            const field = this.selectedUserDataFields.find((df) => df[0] === DashboardPageFieldType.MatchPercent);
            if (field)
            {
                return field[1] as number;
            }
        }
        return null;
    }

    get selectedBaseline()
    {
        // console.log(this.baselines);
        // console.log(this.baselineFilterState.baseline.value);
        return this.baselines
            ? this.baselines.find((b) => 
            {
                const asRef = this.baselineFilterState.baseline.value as RoleBaselineReferenceType;
                if (asRef)
                {
                    return asRef.baseline === b._id;
                }
                return false;
            })
            : null;
    }

    baselineAverage(rb: RoleBaseline): number[]
    {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const avg = this.capabilities.map(_c => 0);
        // console.log(avg);
        let count = 0;
        // console.log(rb.members);
        // console.log(this.tableData);
        rb.members.forEach((m) =>
        {
            const performance = m.userdata.find((v) => v.fieldName.trim().toLowerCase() === 'performance');
            const row = this.tableData.find((d) => d[1].user.email === m.email);
            if (row && (!performance || performance.fieldValue.trim().toLowerCase() === 'high'))
            {
                let mIndex = 0;
                this.capabilities.forEach((c, i) =>
                {
                    avg[i] += row[1].metrics.values[mIndex];
                    ++mIndex;
                    if (c.subCapabilities && c.subCapabilities.length)
                    {
                        mIndex += c.subCapabilities.length;
                    }
                });
                ++count;
            }
        });
        // console.log(count);
        if (count) 
        {
            avg.forEach((_v, i) => avg[i] /= count);
            // console.log(avg);
            return avg;
        }
        return [];
    }

    get selectedBaselineAverage()
    {
        // console.log('*****');
        // console.log(this.selectedUserCapabilities);
        // console.log(this.selectedBaseline);
        if (this.selectedBaseline)
        {
            return this.baselineAverage(this.selectedBaseline);
        }
        return [];
    }

    get baselineAverages(): Map<string, number[]>
    {
        const m = new Map<string, number[]>();
        if (this.baselines != null)
        {
            this.baselines.forEach((b) =>
            {
                if (b && b.name && b.members && b.members.length)
                {
                    m.set(b.name, this.baselineAverage(b));
                }
            });
        }
        return m;
    }

    get roleRecommendations(): string[]
    {
        const recommendations = [];
        const baselineDiffs: { name: string, diff: number }[] = [];
        this.baselineAverages.forEach((v, k) =>
        {
            const diff = v.reduce((p, c, i) => (p + Math.abs((this.selectedUserCapabilities[i] || 0) - c)), 0);
            baselineDiffs.push({ name: k, diff });
        });
        baselineDiffs.sort((a, b) => (a.diff - b.diff));
        // console.log(baselineDiffs);
        if (baselineDiffs.length > 1)
        {
            for (let i = 0; i < 3 && i < baselineDiffs.length - 1; ++i)
            {
                recommendations.push(baselineDiffs[i].name);
            }
        }
        else if (baselineDiffs.length === 1)
        {
            recommendations.push(baselineDiffs[0].name);
        }
        return recommendations;
    }

    get tableTotalCount(): number
    {
        return this.queryResult ? this.queryResult.totalCount : 0;
    }

    onRole(idx: number)
    {
        if (this.baselines)
        {
            const rb = this.baselines.find(b => b.name === this.roleRecommendations[idx]);
            if (rb)
            {
                this.baselineFilterState.baseline.value = { baseline: rb._id, metrics: [] };
            }
        }
    }

    @Watch('tableData')
    onTableDataChanged(newValue: [[DashboardPageFieldType, FieldValueType][], UserInfoAndMetrics<IndividualDataTableUserData>][])
    {
        if (this.selectedUser)
        {
            const row = newValue.find((v) => this.selectedUser && (v[1].user.email === this.selectedUser.user.email));
            if (row) 
            {
                this.updateSelectedUser(row[1]);
                this.$set(this, 'selectedUserDataFields', row[0]);
            }
        }
    }

    shouldRenderFieldInModal(field: BaseDashboardPageField)
    {
        switch(field.type)
        {
        // case DashboardPageFieldType.UserEmail:
        // case DashboardPageFieldType.UserName:
        case DashboardPageFieldType.Company:
        case DashboardPageFieldType.Location:
        case DashboardPageFieldType.DateCompleted:
        case DashboardPageFieldType.Department:
            return true;
        }
        return false;
    }

    searchText = '';

    sortBy = '0';
    sortDesc = true;

    itemsPerPage = 25;

    get footerProps()
    {
        return {
            'items-per-page-options': [10, 25, 50, 100],
        };
    }

    tableHeight()
    {
        return window.innerWidth > 878 ? 'calc(100vh - 245px)' : 'calc(100vh - 310px)';
    }

    onResize()
    {
        this.$forceUpdate();
    }

    @Watch('filterState', { deep: true })
    async onNewFilters(/* newFilterState: BaseFilterQueryData[], oldFilterState: BaseFilterQueryData[] */)
    {
        // console.log('old');
        // oldFilterState.forEach(ofs => console.log(ofs.toValue()));
        // console.log('new');
        // newFilterState.forEach(nfs => console.log(nfs.toValue()));
        // if (oldFilterState.length !== newFilterState.length || oldFilterState.some((ofs, i) => !(ofs.equals(newFilterState[i]))))
        // {
        //     await this.refresh();
        // }

        // for deep watchers, there's no efficient way to get the old value
        // so to avoid firing the watcher when there's no actual changes (generally happens during mounted)
        // we just check a mountRefresh flag, and flip it off the first time it blocks a refresh
        // (so that a user can start a new refresh with actual filter changes before the mounted one finishes)
        // console.log('onNewFilters');
        if (this.mountRefresh)
        {
            this.mountRefresh = false;
        }
        else
        {
            await this.refresh(500);
        }
    }

    currentTablePage = 1;
    async onPageChange(page: number)
    {
        console.log(page);
        // await this.refresh();
    }

    @Watch('itemsPerPage')
    async onItemsPerPageChange(ipp: number)
    {
        console.log(ipp);
        // await this.refresh();
    }

    @Watch('searchText')
    async onSearchTextChange()
    {
        // console.log(newSearchText);
        // await this.refresh(550);
        this.currentTablePage = 1;
    }

    @Watch('sortBy')
    async onSortByChange(newSortBy: number)
    {
        console.log(newSortBy);
        // await this.refresh();
    }

    @Watch('sortDesc')
    async onSortDescChange(newSortDesc: boolean)
    {
        console.log(newSortDesc);
        // await this.refresh();
    }

    customSort(items: [[DashboardPageFieldType, FieldValueType][], UserInfoAndMetrics<IndividualDataTableUserData>][], sortBy: number[], sortDescTab: boolean[])
    {
        // Only support sorting with a single element
        if (sortBy.length != 1) return items;

        const sortDesc = sortDescTab[0];
        const fieldIdx = sortBy[0];
        const field = this.page.fields[fieldIdx];
        const fieldViewerDataFactory = fieldViewerComponents.getDataFactory(field.type);
        if (!fieldViewerDataFactory) return items;

        const fieldViewerData = fieldViewerDataFactory();
        if (!fieldViewerData) return items;
        const sortFn = fieldViewerData.sort;
        if (!sortFn) return items;
        // console.log(field);

        items.sort((a, b)=>
        {
            // console.log(a);
            // console.log(b);
            const res = sortFn(fieldIdx, field.type, a, b);
            // console.log(res);
            if (sortDesc)
            {
                return -res;
            }
            return res;
        });

        // console.log(sortBy);
        return items;
    }

    changeSort(column: number)
    {
        if (this.sortBy == `${column}`)
        {
            this.sortDesc = !this.sortDesc;
        }
        else
        {
            this.sortBy = `${column}`;
            this.sortDesc = true;
        }
    }

    get rowClass()
    {
        if (this.page.benchmarkPageIdx !== null) return 'idt-clickable-row';
        return '';
    }

    clickedTableRow(item: [[DashboardPageFieldType, FieldValueType][], UserInfoAndMetrics<IndividualDataTableUserData>])
    {
        this.isChartReady = false;
        if (this.page.benchmarkPageIdx !== null)
        {
            const endpoint = `/dashboard/page/${this.page.benchmarkPageIdx}/${item[1].user.userid}`;
            this.$router.push(endpoint);
        }
        else 
        {
            this.$set(this, 'selectedUserDataFields', item[0]);
            this.$set(this, 'selectedUser', item[1]);
            this.$nextTick(()=>
            {
                this.isChartReady = true;
                this.$forceUpdate();
                if (this.$refs.areaChart)
                {
                    (this.$refs.areaChart as Vue).$forceUpdate();
                }
            });
        }
    }

    get dashboard()
    {
        if (!dashModule.activeDashboard) throw new Error('No dashboard loaded');
        return dashModule.activeDashboard;
    }

    get filterValues(): FilterValueType[]
    {
        return this.filterState.map(fs => fs.toValue());
    }

    get anySelectedMetrics(): boolean
    {
        return this.filterState.some((fs) =>
            fs.type === DashboardPageFilterType.MetricsToggle
            && (fs as MetricsToggleQueryData).selectedMetrics
            && (fs as MetricsToggleQueryData).selectedMetrics.length);
    }

    get isBaselineActive()
    {
        return this.filterState.some((fs) =>
        {
            if (fs.type === DashboardPageFilterType.RoleBaseline)
            {
                const typedfs = (fs as RoleBaselineQueryData);
                if (typedfs.baseline.manual)
                {
                    const val = typedfs.baseline.value;
                    return Array.isArray(val) && val.length;
                }
                
                const baselineTypeVal = typedfs.baseline.value as RoleBaselineReferenceType;
                if (Array.isArray(baselineTypeVal))
                {
                    return baselineTypeVal.length > 0;
                }
                
                // console.log({
                //     baselineTypeVal,
                //     btvExists: !!baselineTypeVal,
                //     metrics: baselineTypeVal.metrics,
                //     res: (!!baselineTypeVal && (!!baselineTypeVal.baseline || baselineTypeVal.metrics.length > 0))
                // });

                return !!baselineTypeVal && (baselineTypeVal.baseline || baselineTypeVal.metrics.length);
            }
            return false;
        });
    }

    queryResult: IndividualDataTableQueryResult | null = null;

    loading = false;
    mountRefresh = false;

    get tableHeaders()
    {
        const headers = [];
        for(let i = 0; i < this.page.fields.length; ++i)
        {
            const field = this.page.fields[i];
            
            if (!(this.anySelectedMetrics || this.isBaselineActive) && field.type === DashboardPageFieldType.MatchPercent)
            {
                continue;
            }

            const sortable = fieldViewerComponents.getDataFactory(field.type) != undefined;
            headers.push({
                text: field.name,
                value: i,
                sortable,
            });
        }
        return headers;
    }

    get tableData()
    {
        const data: [[DashboardPageFieldType, FieldValueType][], UserInfoAndMetrics<IndividualDataTableUserData>][] = [];
        if (!this.queryResult) return data;
    
        for (const val of this.queryResult.users)
        {
            if (val.pageData)
            {
                const vdata: [DashboardPageFieldType, FieldValueType][] = [];
                for(let i = 0; i < this.page.fields.length; ++i)
                {
                    if (!(this.anySelectedMetrics || this.isBaselineActive) && this.page.fields[i].type === DashboardPageFieldType.MatchPercent)
                    {
                        continue;
                    }
                    vdata.push([this.page.fields[i].type, val.pageData.fieldValues[i]]);
                }
                data.push([vdata, val]);
            }
        }
        return data;
    }

    get filteredTableData()
    {
        // return this.tableData;
        // searchText is now processed by the backend query instead of the following frontend code

        const data = this.tableData;
        if (this.searchText.trim().length === 0) return data;

        const search = this.searchText.trim().toLowerCase();
        return this.tableData.filter(a=>
        {
            const user = a[1].user;
            return user.fullName.toLowerCase().includes(search) || user.email.toLowerCase().includes(search);
        });
    }

    downloadReportFileName = '';
    showDownloadReportModal = false;
    onClickDownload()
    {
        this.downloadReportFileName = '';
        this.showDownloadReportModal = true;
    }

    downloadTableAsCSV()
    {
        this.showDownloadReportModal = false;
        if (!(this.downloadReportFileName))
        {
            this.downloadReportFileName = this.page.name;
        }
        // console.log(this.sortBy);
        // console.log(this.sortDesc);
        const sb = parseInt(this.sortBy);
        // console.log(sb);
        const data = this.customSort(this.filteredTableData, [sb], [this.sortDesc]);
        downloadIndividualDataTableCSV(data, this.tableHeaders, this.dashboard, `${this.downloadReportFileName}.csv`);
    }

    copyFilteredTableDataToClipboard(generatePerformance = false, setPerformance = '')
    {
        // console.log(this.sortBy);
        // console.log(this.sortDesc);
        const sb = parseInt(this.sortBy);
        // console.log(sb);
        const data = this.customSort(this.filteredTableData, [sb], [this.sortDesc]).filter((_, index) => index < 200);
        let maxAverage = 1;
        const averages: number[] = [];
        if (generatePerformance) // TODO: allow manual mode to specify
        {
            data.forEach((v) =>
            {
                let avg = 0;
                if ((v[1].metrics && v[1].metrics.values && v[1].metrics.values.length > 0))
                {
                    avg = (v[1].metrics.values.reduce((p, c) => p + c, 0) / v[1].metrics.values.length);
                }
                averages.push(avg);
                if (maxAverage < avg)
                {
                    maxAverage = avg;
                }
            });
        }
        console.log(averages);
        console.log(maxAverage);
        copy(`email,name${generatePerformance ? ',performance' : ''}\n${data.map((v, index) =>
        {
            if (v[1] && v[1].user)
            {
                if (generatePerformance)
                {
                    if (setPerformance)
                    {
                        return `${v[1].user.email},"${v[1].user.fullName.replaceAll(/"/g, '""')}",${setPerformance}`;
                    }
                    const performance =
                        (index < 6)
                            ? (index % 3 + 1)
                            : ((v[1].metrics && v[1].metrics.values && v[1].metrics.values.length > 0)
                                ? (4 - Math.ceil(3 * (averages[index] / (maxAverage + 0.25))))
                                : Math.ceil(3 * Math.random()));
                    const performanceString =
                        (performance === 1)
                            ? 'High'
                            : 'Low';
                        // : (performance === 2)
                        //     ? 'Middle'
                        //     : 'Low';
                    return `${v[1].user.email},"${v[1].user.fullName.replaceAll(/"/g, '""')}",${performanceString}`;
                }
                return `${v[1].user.email},"${v[1].user.fullName.replaceAll(/"/g, '""')}"`;
            }
            return '';
        }).join('\n')}`);
    }

    onKeyDown(event: KeyboardEvent)
    {
        const ctrl = (event.ctrlKey || event.metaKey);
        if (event.key === 'x')
        {
            if (event.altKey && ctrl)
            {
                this.copyFilteredTableDataToClipboard(true, 'High');
            }
            else if (event.altKey)
            {
                this.copyFilteredTableDataToClipboard(true, 'Low');
            }
            else if (ctrl)
            {
                this.copyFilteredTableDataToClipboard(true);
            }
        }
        else if (ctrl && event.key === 'c')
        {
            this.copyFilteredTableDataToClipboard();
        }
    }

    async refresh(batchCheckDelay = 0)
    {
        // TODO: Once backend pagination is in place, do the filtering serverside.
        // Right now, there's no reason to do it on the backend as the entire table is loaded anyway.
        const serverSearchText = '';
        // const searchText = this.searchText;
        if (batchCheckDelay)
        {
            // console.log('refresh?');
            const prevQueryValues = JSON.stringify({
                filterValues: this.filterValues,
                pageData: {
                    searchText: serverSearchText,
                },
            });
            await new Promise(resolve => setTimeout(resolve, batchCheckDelay));
            const currQueryValues = JSON.stringify({
                filterValues: this.filterValues,
                pageData: {
                    searchText: serverSearchText,
                },
            });
            if (currQueryValues !== prevQueryValues) return;
            // console.log('refresh');
        }

        this.loading = true;
        try
        {
            const data = await dataSourceModule.queryIndividualUserTablePage({
                dashboard: await dashModule.getActiveDashboard(),
                pageIdx: this.index,
                filterValues: this.filterValues,
                pageData: {
                    tablePage: this.currentTablePage,
                    itemsPerPage: this.itemsPerPage,
                    searchText: serverSearchText,
                    sortBy: this.sortBy,
                    sortDesc: this.sortDesc,
                },
            });
            
            if (data)
            {
                this.$set(this, 'queryResult', data.data);
            }
            else
            {
                this.$set(this, 'queryResult', null);
            }
        }
        finally
        {
            this.loading = false;
        }
    }

    baselines: RoleBaseline[] | null = null;
    baselineOptions: { text: string, value: string }[] = [];
    async mounted()
    {
        // console.log(this.filterState);
        this.mountRefresh = true;
        await this.refresh();
        this.mountRefresh = false;
        this.baselines = await roleBaselineModule.getAllInDashboard((await dashModule.getActiveDashboard())._id);
        // console.log(this.baselines);
        this.baselineOptions = this.baselines ? this.baselines.map(a => ({ text: a.name, value: a._id })) : [];
    }

    created()
    {
        window.addEventListener("resize", this.onResize);
        window.addEventListener("keydown", this.onKeyDown);
    }

    destroyed()
    {
        window.removeEventListener("resize", this.onResize);
        window.removeEventListener("keydown", this.onKeyDown);
    }
}

pageViewerComponents
    .registerComponent(DashboardPageType.IndividualDataTable, IndividualDataTable)
    .registerDataFactory(DashboardPageType.IndividualDataTable, ()=>new IndividualDataTableDashboardPage)
;
