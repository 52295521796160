












































import { Component, Vue, Prop } from 'vue-property-decorator';
import { MockDataSourceConfig, DataSourceTypes, Dashboard } from '@/models/hcad/shared/dashboard';
import { dataSourceEditComponents } from '@/utils/typed-configs';
import validationRules from '@/utils/validation-rules';
import { validateRefs } from '@/utils/validate-refs';
import MockDepartmentList from './mock/MockDepartmentList.vue';
import MockLocationList from './mock/MockLocationList.vue';

@Component({components: {MockDepartmentList, MockLocationList}})
export default class MockDataSourceConfigEdit extends Vue
{
    @Prop({type: Object, required: true})
    config!: MockDataSourceConfig;

    @Prop({type: Object, required: true})
    dashboard!: Dashboard;

    rules = validationRules;

    mounted()
    {
        // Ensure that at least the root object is properly initialized
        this.dashboard.dataSourceConfigs.mock = Object.assign(new MockDataSourceConfig(), this.dashboard.dataSourceConfigs.mock);
    }

    validate()
    {
        return validateRefs(this.$refs);
    }

    onUseSubCapabilitiesAverageChange()
    {
        this.config.disableSubCapabilityConstraints = this.config.useSubCapabilitiesAverage;
    }

    get dashboardHasSubCapabilities()
    {
        return this.dashboard.capabilities
            .some((cat) => cat.capabilities
                .some((cap) => cap.subCapabilities && cap.subCapabilities.length > 0));
    }
}

dataSourceEditComponents
    .registerComponent(DataSourceTypes.Mock, MockDataSourceConfigEdit)
    .registerDataFactory(DataSourceTypes.Mock, ()=>new MockDataSourceConfig)
;
