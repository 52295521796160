import { render, staticRenderFns } from "./MockDataSourceEdit.vue?vue&type=template&id=32ef3726&"
import script from "./MockDataSourceEdit.vue?vue&type=script&lang=ts&"
export * from "./MockDataSourceEdit.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCheckbox,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels,VTextField})
