import { random } from 'lodash';
import { SharedObjectId as ObjectID } from '../shared-aliases';
import { FilterValueType, RoleBaselineFilterValueType, UserInfoAndMetrics } from './queries';

/* eslint-disable */
export enum DashboardPageType
{
    Invalid,
    Benchmark,
    IndividualDataTable,
    RoleBaselinePage,
    AttritionAnalysis,
    DashboardGroupManager,
    GroupDataTable,
    Count
}

export enum DashboardPageFilterType
{
    Invalid,
    YearsEmployedRange,
    LocationSelect,
    DepartmentSelect,
    FunctionSelect,
    JobCodeSelect,
    MetricsToggle,
    CompanySelect,
    RoleBaseline,
    Count
}

export enum DashboardPageFieldType
{
    Invalid,

    // number (0-1)
    MatchPercent,
    
    // null (pulled from user data)
    UserName,

    // null (pulled from user data)
    UserEmail,

    // string
    Department,

    // string
    Function,
    
    // string[]
    Capabilities,
    
    // string
    TimeInOrganization,

    // string
    Location,

    // string
    JobCode,

    // date
    DateCompleted,

    // string
    Company,

    // number (0-1)
    LongevityPrediction,

    Count
}

export function fieldTypeToFilterType(type: DashboardPageFieldType)
{
    switch (type)
    {
        case DashboardPageFieldType.Capabilities:
            return DashboardPageFilterType.MetricsToggle;
        case DashboardPageFieldType.Department:
            return DashboardPageFilterType.DepartmentSelect;
        case DashboardPageFieldType.Function:
            return DashboardPageFilterType.FunctionSelect;
        case DashboardPageFieldType.JobCode:
            return DashboardPageFilterType.JobCodeSelect;
        case DashboardPageFieldType.Location:
            return DashboardPageFilterType.LocationSelect;
        case DashboardPageFieldType.TimeInOrganization:
            return DashboardPageFilterType.YearsEmployedRange;
        case DashboardPageFieldType.Company:
            return DashboardPageFilterType.CompanySelect;
        case DashboardPageFieldType.UserEmail:
        case DashboardPageFieldType.UserName:
        case DashboardPageFieldType.MatchPercent:
        case DashboardPageFieldType.DateCompleted:
        case DashboardPageFieldType.LongevityPrediction:
        default:
            return DashboardPageFilterType.Invalid;
    }
}

export function filterTypeToFieldType(type: DashboardPageFilterType)
{
    switch (type)
    {
        case DashboardPageFilterType.MetricsToggle:
            return DashboardPageFieldType.Capabilities;
        case DashboardPageFilterType.DepartmentSelect:
            return DashboardPageFieldType.Department;
        case DashboardPageFilterType.FunctionSelect:
            return DashboardPageFieldType.Function;
        case DashboardPageFilterType.JobCodeSelect:
            return DashboardPageFieldType.JobCode;
        case DashboardPageFilterType.LocationSelect:
            return DashboardPageFieldType.Location;
        case DashboardPageFilterType.YearsEmployedRange:
            return DashboardPageFieldType.TimeInOrganization;
        case DashboardPageFilterType.CompanySelect:
            return DashboardPageFieldType.Company;
        default:
            return DashboardPageFieldType.Invalid;
    }
}

/**
 * Get the corresponding field types of the filter types and
 * combine them with the specified field types without any duplicates.
 */
export function getAugmentedFieldList(fieldTypes: DashboardPageFieldType[], filterTypes: DashboardPageFilterType[])
{
    const augmentingTypes = getDiminishedFieldList(fieldTypes, filterTypes);
    return [...fieldTypes, ...augmentingTypes];
}

/**
 * Get the corresponding field types of the filter types that are not in the specified field types.
 */
export function getDiminishedFieldList(fieldTypes: DashboardPageFieldType[], filterTypes: DashboardPageFilterType[])
{
    return (filterTypes
        .map(filterTypeToFieldType)
        .filter(t => t !== DashboardPageFieldType.Invalid && !(fieldTypes.includes(t))));
}

/**
 * Get the filter types for which the corresponding field types are not in the specified field types.
 */
export function getDiminishedFilterList(fieldTypes: DashboardPageFieldType[], filterTypes: DashboardPageFilterType[])
{
    return (filterTypes
        .filter(t => t !== DashboardPageFilterType.Invalid && !(fieldTypes.includes(filterTypeToFieldType(t)))));
}

export enum DataSourceTypes
{
    Mock = 'mock',
    Blade = 'blade'
}
/* eslint-enable */

export class EnvironmentSettings
{
    tabText = '';

    headerLogo = '';

    headerText = '';

    emailDomain = '';

    displaySidebar = false;

    displayFooter = false;

    lowerLogo = '';

    lowerLogoText = '';

    // TODO: Branding colors
}

export class DataSourceConfig
{
    allowMetricFiltering = false;

    type = '';

    // Additional configuration stuff defined in extension types
}

export class MockDataSourceJobCodeConfig
{
    static clone(src: MockDataSourceJobCodeConfig)
    {
        return JSON.parse(JSON.stringify(src)) as MockDataSourceJobCodeConfig;
    }

    name = 'New Job Code'

    relativeNumberOfUsers: [number, number] = [0, 1];

    metricMultiplier = 1;

    // 0-1 range
    metricOutlierRate = 0.25;

    // 0-N range
    yoeRange: [number, number] = [0, 1];

    // 0-1 range
    yoeMetricEffect: [number, number] = [0.5, 1];

    // -N to +N ranges
    metricRanges: [number, number][] = [];
}

const uuidchars = 'abcdef';
export function randomId()
{
    let s = '';
    while (s.length < 24)
    {
        const rand = random(0, 16, false);
        if (rand > 9)
        {
            s += uuidchars.charAt(rand - 9);
        }
        else
        {
            s += `${rand}`;
        }
    }
    return s;
}

export class MockDataSourceFunctionConfig
{
    static clone(src: MockDataSourceFunctionConfig)
    {
        return JSON.parse(JSON.stringify(src)) as MockDataSourceFunctionConfig;
    }

    name = 'New Function';

    relativeNumberOfUsers: [number, number] = [0, 1];

    jobs: MockDataSourceJobCodeConfig[] = [];
}

export class MockDataSourceDepartmentConfig
{
    static clone(src: MockDataSourceDepartmentConfig)
    {
        const tg = JSON.parse(JSON.stringify(src)) as MockDataSourceDepartmentConfig;
        tg.id = randomId();
        return tg;
    }

    // Needs consistent IDs for the location mapping
    id = randomId();

    name = 'New Department';

    functionSet: MockDataSourceFunctionConfig[] = []
}

export class MockDataSourceLocationConfig
{
    static clone(src: MockDataSourceLocationConfig)
    {
        return JSON.parse(JSON.stringify(src)) as MockDataSourceLocationConfig;
    }

    name = 'New Location';

    // object of values where keys are department IDs and values are ranges ([number, number])

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    departmentRelativeCounts: any = {};
}

export class MockDataSourceConfig extends DataSourceConfig
{
    static clone(src: MockDataSourceConfig)
    {
        return JSON.parse(JSON.stringify(src)) as MockDataSourceConfig;
    }

    constructor()
    {
        super();
        this.type = DataSourceTypes.Mock;
    }

    companyName = 'New Company';

    useSubCapabilitiesAverage = false;

    disableSubCapabilityConstraints = false;

    departments: MockDataSourceDepartmentConfig[] = [];

    locations: MockDataSourceLocationConfig[] = [];
}

export class BladeDataSourceConfig extends DataSourceConfig
{
    /** Filter out users that previously played the workgroup but are no longer assigned to the workgroup */
    showOnlyCurrentlyAssignedUsers = false;

    static clone(src: BladeDataSourceConfig)
    {
        return JSON.parse(JSON.stringify(src)) as BladeDataSourceConfig;
    }

    constructor()
    {
        super();
        this.type = DataSourceTypes.Blade;
    }
}

export class ValueColor
{
    maxValue = 0;

    iconUrl: string | null = null;

    iconClass: string | null = null;

    color = '#FF0000FF';
}

export class Capability
{
    static clone(src: Capability): Capability
    {
        const tg = new Capability();
        Object.assign(tg, src);
        tg.subCapabilities = [...tg.subCapabilities];
        for (let i = 0; i < tg.subCapabilities.length; ++i)
        {
            tg.subCapabilities[i] = Capability.clone(tg.subCapabilities[i]);
        }
        return tg;
    }

    _id = randomId();

    name = 'New Capability';

    description = '';

    iconUrl: string | null = null;

    iconClass: string | null = null;

    iconUrlSelected: string | null = null;

    iconClassSelected: string | null = null;

    // Falls back to the root range if null
    range: [number, number] | null = null;

    color = '#FF0000FF';

    enableHoverText = false;

    hoverText = '';

    enableFilterHoverText = false;

    filterHoverText = '';

    valueColors: ValueColor[] = [];

    subCapabilities: Capability[] = [];

    // frontend-only state variables
    expanded = false;

    selected = false;

    targetValue = 0;

    sub = false;
}

export class CapabilityCategory
{
    static clone(src: CapabilityCategory)
    {
        const tg = new CapabilityCategory();
        Object.assign(tg, src);
        tg.capabilities = [...tg.capabilities];
        for (let i = 0; i < tg.capabilities.length; ++i)
        {
            tg.capabilities[i] = Capability.clone(tg.capabilities[i]);
        }
        return tg;
    }

    name = 'New Category';

    color = '#FF0000FF';

    iconUrl: string | null = null;

    iconClass: string | null = null;

    capabilities: Capability[] = [];
}

export class ControlGroup
{
    id = randomId();

    label = '';

    useFilterPresets = false;
}

export class BaseDashboardPageFilter
{
    static clone(src: BaseDashboardPageFilter): BaseDashboardPageFilter
    {
        return JSON.parse(JSON.stringify(src)) as BaseDashboardPageFilter;
    }

    type = DashboardPageFilterType.Invalid;

    label = '';

    description = '';

    groupId = '';

    constructor(type: DashboardPageFilterType, label = '')
    {
        this.type = type;
        this.label = label;
    }
}

export abstract class BaseFilterQueryData
{
    type = DashboardPageFilterType.Invalid;

    constructor(type: DashboardPageFilterType)
    {
        this.type = type;
    }

    abstract toValue(): FilterValueType;

    abstract equals(filterData: BaseFilterQueryData): boolean;
}

export class DashboardPageSelectFilter extends BaseDashboardPageFilter
{
    options: Array<string> = [];

    useValuesFromDataSource = false;

    constructor(type: DashboardPageFilterType, options: Array<string>, label = '')
    {
        super(type, label);
        this.options = options;
    }
}

export class SelectFilterQueryData extends BaseFilterQueryData
{
    selectedOption: string | null = null;

    constructor(type: DashboardPageFilterType, selectedOption: string | null = null)
    {
        super(type);
        this.selectedOption = selectedOption;
    }

    toValue()
    {
        return this.selectedOption;
    }

    equals(filterData: BaseFilterQueryData): boolean
    {
        if (filterData.type === this.type)
        {
            const typedFilterData = filterData as SelectFilterQueryData;
            if (typedFilterData)
            {
                return this.selectedOption === typedFilterData.selectedOption;
            }
        }
        return false;
    }
}

export class DashboardPageRangeFilter extends BaseDashboardPageFilter
{
    min = 0;

    max = 20;

    unit = '';

    constructor(type: DashboardPageFilterType, min = 0, max = 20, label = '', unit = '')
    {
        super(type, label);
        this.min = min;
        this.max = max;
        this.unit = unit;
    }
}

export class RangeFilterQueryData extends BaseFilterQueryData
{
    selectedMin = 0;

    selectedMax = 20;

    constructor(type: DashboardPageFilterType, selectedMin = 0, selectedMax = 20)
    {
        super(type);
        this.selectedMin = selectedMin;
        this.selectedMax = selectedMax;
    }

    toValue()
    {
        return [this.selectedMin, this.selectedMax];
    }

    equals(filterData: BaseFilterQueryData): boolean
    {
        if (filterData.type === this.type)
        {
            const typedFilterData = filterData as RangeFilterQueryData;
            if (typedFilterData)
            {
                return this.selectedMin === typedFilterData.selectedMin
                    && this.selectedMax === typedFilterData.selectedMax;
            }
        }
        return false;
    }
}

export class DashboardPageMetricsToggleFilter extends BaseDashboardPageFilter
{
    multiple = false;

    allowTargetSelection = false;

    constructor(type: DashboardPageFilterType, multiple = false, allowTargetSelection = false, label = '')
    {
        super(type, label);
        this.multiple = multiple;
        this.allowTargetSelection = allowTargetSelection;
    }
}

export class MetricsToggleQueryData extends BaseFilterQueryData
{
    selectedMetrics: Array<Capability> = [];

    constructor(type: DashboardPageFilterType, selectedMetrics: Array<Capability> = [])
    {
        super(type);
        this.selectedMetrics = selectedMetrics;
    }

    toValue() : [string, number][]
    {
        return this.selectedMetrics.map((c) => [c.name, c.targetValue]);
    }

    equals(filterData: BaseFilterQueryData): boolean
    {
        if (filterData.type === this.type)
        {
            const typedFilterData = filterData as MetricsToggleQueryData;
            if (typedFilterData)
            {
                return this.selectedMetrics.length === typedFilterData.selectedMetrics.length
                    && this.selectedMetrics.every((c, i) => c._id === typedFilterData.selectedMetrics[i]._id);
            }
        }
        return false;
    }
}

export class DashboardPageRoleBaselineFilter extends BaseDashboardPageFilter
{
    allowManualSelect = false;

    departmentLabel = 'Department';

    functionLabel = 'Function';

    jobCodeLabel = 'Job Code';

    locationLabel = 'Location';

    timeInOrgLabel = 'Years Employed';

    allowCultureFitFineTuning = false;

    metricToggleConfig: DashboardPageMetricsToggleFilter =
        new DashboardPageMetricsToggleFilter(DashboardPageFilterType.MetricsToggle, true, false, '');

    constructor(type: DashboardPageFilterType, allowManualSelect = true, label = '')
    {
        super(type, label);
        this.allowManualSelect = allowManualSelect;
        this.metricToggleConfig = new DashboardPageMetricsToggleFilter(DashboardPageFilterType.MetricsToggle, true, false, '');
    }
}
export class RoleBaselineQueryData extends BaseFilterQueryData
{
    isManual: boolean;

    baseline: RoleBaselineFilterValueType;

    constructor(
        type: DashboardPageFilterType,
        isManual = false,
        selectedRole: RoleBaselineFilterValueType = {
            manual: false,
            value: { baseline: null, metrics: [] },
            broadenFitSlider: 0,
            cultureAddBroadenFitSlider: 0.3,
            combineMode: 'or'
        }
    )
    {
        super(type);
        this.baseline = selectedRole;
        this.isManual = isManual;
    }

    toValue()
    {
        return this.baseline;
    }

    equals(filterData: BaseFilterQueryData): boolean
    {
        if (filterData.type === this.type)
        {
            const typedFilterData = filterData as RoleBaselineQueryData;
            if (typedFilterData)
            {
                // TODO: this.baseline needs more involved comparison to avoid false negatives
                return this.baseline === typedFilterData.baseline
                    && this.isManual === typedFilterData.isManual
                    && this.baseline.broadenFitSlider === typedFilterData.baseline.broadenFitSlider;
            }
        }
        return false;
    }
}

export class DashboardPageFilterConfig
{
    filterViewEnabled = true;

    savedFilterPresetsEnabled = false;

    headerLabel = 'Filters';

    groups: ControlGroup[] = [];

    filters: BaseDashboardPageFilter[] = [];
}

export class BaseDashboardPageField
{
    static clone(src: BaseDashboardPageField)
    {
        return JSON.parse(JSON.stringify(src)) as BaseDashboardPageField;
    }

    constructor(type: DashboardPageFieldType)
    {
        this.type = type;
    }

    name = '';

    type: DashboardPageFieldType;
}

export interface DashboardPageField<T> extends BaseDashboardPageField
{
    config: T;
}

export class BaseDashboardPage
{
    static clone(src: BaseDashboardPage)
    {
        return JSON.parse(JSON.stringify(src)) as BaseDashboardPage;
    }

    name = 'New Dashboard Page';

    type: DashboardPageType = DashboardPageType.Invalid;

    hideInTabBar = false;

    filterConfig = new DashboardPageFilterConfig();
}

export class AggregationSettings
{
    static clone(src: AggregationSettings)
    {
        return JSON.parse(JSON.stringify(src)) as AggregationSettings;
    }

    iconClass = '';

    iconUrl = '';

    color = '#FF0000FF';

    defaultValue = '';
}

export class BenchmarkDashboardPage extends BaseDashboardPage
{
    constructor()
    {
        super();
        this.type = DashboardPageType.Benchmark;
    }

    dataTablePageIdx: number | null = null;

    aggregationFields: BaseDashboardPageField[] = [];

    aggregationSettings: AggregationSettings[] = [];

    includeGlobal = false;

    globalAggregationSettings?: AggregationSettings;
}

export class IndividualDataTableDashboardPage extends BaseDashboardPage
{
    constructor()
    {
        super();
        this.type = DashboardPageType.IndividualDataTable;
    }

    benchmarkPageIdx: number | null = null;

    showInterviewGuide = false;

    fields: BaseDashboardPageField[] = [];
}

export class GroupDataTableDashboardPage extends BaseDashboardPage
{
    constructor()
    {
        super();
        this.type = DashboardPageType.GroupDataTable;
        this.filterConfig.filterViewEnabled = false;
    }
}

export class RoleBaselineDashboardPage extends BaseDashboardPage
{
    constructor()
    {
        super();
        this.type = DashboardPageType.RoleBaselinePage;
        this.filterConfig.filterViewEnabled = false;
    }

    departmentLabel = 'Department';

    functionLabel = 'Function';

    jobCodeLabel = 'Job Code';

    locationLabel = 'Location';

    timeInOrgLabel = 'Years Employed';
}

export class DashboardGroupManagerDashboardPage extends BaseDashboardPage
{
    constructor()
    {
        super();
        this.type = DashboardPageType.DashboardGroupManager;
        this.filterConfig.filterViewEnabled = false;
    }
}

export class AttritionAnalysisDashboardPage extends BaseDashboardPage
{
    constructor()
    {
        super();
        this.type = DashboardPageType.AttritionAnalysis;
        this.filterConfig.filterViewEnabled = false;
    }
}

export class AttritionAnalysisData
{
    averageMetricValues: number[] = [];

    size = 0;

    significantFeatures: boolean[] = [];

    featureImportance: number[] = [];

    shapValues: number[] = [];

    xTest: number[] = [];
}

export class BenchmarkData
{
    metricValues: number[] = [];

    metricValuePercentages: number[] = [];

    categoryPercentages: number[] = [];

    targetName?: string;

    metricMax = 0;
}

export class BenchmarkQueryData
{
    targetId?: string;
}

export type IntermediateMatchPercentageType = { metricScore?: number | undefined };
export type FieldValueType = number | string | string[] | Date | IntermediateMatchPercentageType | null;
export class IndividualDataTableUserData
{
    fieldValues: FieldValueType[] = [];

    pushField(val: FieldValueType)
    {
        this.fieldValues.push(val);
    }
}

export class IndividualDataTableQueryResult
{
    users: UserInfoAndMetrics<IndividualDataTableUserData>[] = [];

    totalCount = 0;
}

export class DashboardDataSourceConfig
{
    mock: MockDataSourceConfig | undefined;

    blade: BladeDataSourceConfig | undefined;
}

// Note: Keep this in sync with DashboardDocument
export class Dashboard
{
  _id = '';

  name = 'New Dashboard';

  active = false;

  internal = false;

  targetDomain = '';

  registrationLink?: string | null = null;

  environmentSettings = new EnvironmentSettings();

  dataSourceConfigs = new DashboardDataSourceConfig();

  capabilityRange: [number, number] = [0, 5];

  capabilities: CapabilityCategory[] = [];

  allowFractionalCapabilityValues = false;

  pages: BaseDashboardPage[] = [];
}

type UserType =
{
    forceCanViewDashboards?: boolean | undefined,
    showOnlyAssociatedDashboards?: boolean | undefined,
    email: string,
    roles:
    {
        _id: string
    }[],
    classes: (ObjectID | undefined)[],
};
type ClassType =
{
    displayInDashboardsByDefault: boolean,
    defaultDashboardId: ObjectID,
    dashboardProfiles:
    {
        _id:
        {
            dashboard: { _id: ObjectID },
        }
    }[],
    game:
    {
        dashboardProfiles:
        {
            _id:
            {
                dashboard: { _id: ObjectID },
            }
        }[];
    },
};

export const dashboardAuthorizedRoles = ['owner', 'developer', 'blade', 'admin-content', 'admin', 'manager'];
export function canUserViewDashboard(dashboard: Dashboard, user: UserType, classes: ClassType[])
{
    // check role or override setting
    if (dashboardAuthorizedRoles.some((role) => user.roles.some(({ _id }) => role === _id))
        || user.forceCanViewDashboards)
    {
        // check if associated with the dashboard or if they should just be shown all dashboards
        if (!(user.showOnlyAssociatedDashboards)
            || classes.some(
                (c) => (c.defaultDashboardId && `${c.defaultDashboardId}` === `${dashboard._id}`)
                    || c.dashboardProfiles.some(
                        (dp) => `${dp._id.dashboard._id}` === `${dashboard._id}`
                    )
                    || (c.displayInDashboardsByDefault && c.game && c.game.dashboardProfiles.some(
                        (dp) => `${dp._id.dashboard._id}` === `${dashboard._id}`
                    ) && c.dashboardProfiles.every(
                        (dp) => `${dp._id.dashboard._id}` !== `${dashboard._id}`
                    ))
            )
        )
        {
            return true;
        }
    }

    // check dashboard's email domains

    if (!dashboard.environmentSettings.emailDomain
        || dashboard.environmentSettings.emailDomain.length === 0) return false;

    const atIndex = user.email.indexOf('@');
    if (atIndex === -1) return false;

    const domainLower = user.email.substring(atIndex + 1).toLowerCase();
    const domains = dashboard.environmentSettings.emailDomain.split(', ').map((a) => a.trim());

    for (const tg of domains)
    {
        if (tg.toLowerCase() === domainLower)
        {
            return true;
        }
    }
    return false;
}
